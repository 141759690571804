<template>
  <div>
    <b-card>
      <b-card-text>
        <div>
          <div class="row">
            <div class="col-md-4">
              <b-form-group label="Nombre del grupo">
                <b-form-input v-model="form.name" required></b-form-input>
              </b-form-group>
            </div>
            <div class="col-md-4">
              <b-form-group label="Permisos">
                <b-form-select
                  v-model="form.permissions"
                  :options="permissions"
                  multiple
                  :select-size="10"
                  text-field="name"
                  value-field="id"
                ></b-form-select>
              </b-form-group>
            </div>
            <div class="col-md-4">
              <b-form-group label="Usuarios">
                <b-form-select
                  v-model="form.users"
                  :options="users"
                  multiple
                  :select-size="10"
                  text-field="full_name"
                  value-field="id"
                ></b-form-select>
              </b-form-group>
            </div>
          </div>
        </div>
      </b-card-text>
      <template #footer>
        <b-button variant="primary" @click="save">Guardar </b-button>
      </template>
    </b-card>
  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import ApiService from "@/core/services/api.service";

export default {
  name: "GroupCreate",
  data() {
    return {
      isEdit: false,
      form: { name: null, users: [], permissions: [] },
      permissions: [],
      users: [],
    };
  },
  mounted() {
    this.getPermissions();
    this.getUsers();
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Serie" },
      { title: "Configurar Serie" },
    ]);
    if (this.$route.params.id !== undefined) {
      this.isEdit = true;
      this.getGroup();
    }
  },
  methods: {
    getGroup() {
      ApiService.get(`core/group/${this.$route.params.id}`).then((res) => {
        this.form = res.data;
      });
    },
    getPermissions() {
      ApiService.get("core/permission", "?page=1&page_size=1000000").then(
        (res) => {
          this.permissions = res.data.results;
        }
      );
    },
    getUsers() {
      ApiService.get("core/user", "?page=1&page_size=1000000").then((res) => {
        this.users = res.data.results;
      });
    },
    save() {
      const method =
        this.isEdit === true
          ? ApiService.put(`core/group/${this.$route.params.id}/`, this.form)
          : ApiService.post("core/group/", this.form);

      method
        .then((res) => {
          console.log(res);
        })
        .finally(() => {
          window.location.reload();
        });
    },
  },
};
</script>
